import Page from '@/components/layout/page'
import { AppProps } from 'next/app'

import '../styles/globals.css'
import React, { useEffect } from 'react'
import { I18nProvider } from '@lingui/react'
import { Trans, t } from '@lingui/macro'
import lingui from '@/utils/setupLingui'
import { NotificationProvider } from '@/context/notification-context'
import 'react-responsive-modal/styles.css'

function MyApp({ Component, pageProps, router }: AppProps) {
  useEffect(() => {
    router.locale && lingui.activate(router.locale)
  }, [router.locale])
  return (
    <I18nProvider i18n={lingui}>
      <NotificationProvider>
        {pageProps.json?._logged_out ? (
          <Page title={t`Abgemeldet`}>
            <h1>
              <Trans>Abgemeldet</Trans>
            </h1>
            <p>
              <Trans>Bitte melden Sie sich wieder an.</Trans>
            </p>
          </Page>
        ) : (
          <Component {...pageProps} />
        )}
      </NotificationProvider>
    </I18nProvider>
  )
}

export default MyApp
